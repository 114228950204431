import React, { HTMLProps } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/lobster";
import "@fontsource/asap";
import "@fontsource/roboto";
import "@fontsource/open-sans";

import { Navigation, Footer, Date } from "./index";


const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 mx-8 my-6 text-black text-center justify-center min-h-screen" style={{ fontFamily: "Open Sans" }}>
      <h2 className="text-3xl font-bold mb-14" style={{ fontFamily: "Asap" }}>Contact Us</h2>
      <span className="text-2xl font-bold">Cansativa Organics, Inc.</span>
      <p>info@cansativa.com</p>
      <p>800-555-1268</p>
    </div>
  );
}

const ContactUsPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen bg-cansativa-organics-gray-100">
      <Navigation />
      <Body />
      <Date />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
